import React from 'react'; 
import { Table, Button } from 'react-bootstrap';
import ZoneListItem from './ZoneListItem.component';
import AddZone from './AddZone.component';
import PropTypes from 'prop-types';

function ZoneList ({zones, apiKey, refreshZones}) {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>
                            <strong>Full Title</strong>
                        </th>
                        <th>
                            <strong>Zone Designation</strong>
                        </th>
                        <th>
                            <strong>Price</strong>
                        </th>
                        <th>
                            <strong>Postal Codes</strong>
                        </th>
                        <th>
                            
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        zones.map(zone => 
                            <ZoneListItem 
                                zone={zone} 
                                apiKey={apiKey} 
                                refreshZones={refreshZones}
                            />
                        )
                    }
                    <AddZone 
                        apiKey={apiKey} 
                        refreshZones={refreshZones}
                    />
                </tbody>
            </Table>
        )
    
}

ZoneListItem.propTypes = {
    zone: PropTypes.array.isRequired,
    apiKey: PropTypes.string.isRequired,
    refreshZones: PropTypes.func.isRequired
}

export default ZoneList;