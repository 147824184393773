const axios = require('axios').default;

const fetchTeams = async () => {
  const usersEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/users/teams`
  const result = await axios.get(
    usersEndpoint,
    {withCredentials:true}
  );
  
  const teams = result.data;
    return teams
}

export default fetchTeams;