import React, { useState, useEffect } from 'react';

import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'

import Navbar from './Navbar.component'
import Admins from '../admins/Admins.component'
import Home from '../home/Home.component'
import Messages from '../messages/Messages.component'
import Orders from '../orders/Orders.component'
import Users from '../users/Users.component'
import PackageTypes from '../packageTypes/PackageTypes.component'
import Settings from '../settings/Settings.component'
import Zones from '../zones/Zones.component'
import Layout from './Layout.component'
import Login from '../login/Login.component'
import CustomRates from '../customRates/CustomRates.component';

import fetchAdmin from '../../services/fetchAdmin.service';

function AppRouter() {
    const [loggedIn, setLoggedIn] = useState(true);

    useEffect(() => {
      checkIsLoggedIn();
    })

    const checkIsLoggedIn = async () => {
      const response = await fetchAdmin();
      setLoggedIn(!!response);
    }

    return (
      <Router>
        <Switch>
          <Route 
          path='/login' 
          render={ () => (
            loggedIn
            ? (<Redirect to="/"/>)
            : (<Login checkIsLoggedIn={checkIsLoggedIn}/>)
            )} 
          />

          <Route path='/'>
          <Navbar checkIsLoggedIn={checkIsLoggedIn}/>
          <Layout>
            <Switch>
              <Route 
              exact 
              path='/' 
              render={ () => (
                loggedIn 
                ? (<Home/>) 
                : (<Redirect to='/login'/>) 
                )}
              />
              
              <Route 
              path='/admins' 
              render={ () => (
                loggedIn 
                ? (<Admins/>) 
                : (<Redirect to='/login'/>) 
                )}
              />
                  
              <Route 
              path='/messages' 
              render={ () => (
                loggedIn 
                ? (<Messages/>) 
                : (<Redirect to='/login'/>) 
                )}
              />

              <Route 
              path='/orders'
              render={ () => (
                loggedIn 
                ? (<Orders/>) 
                : (<Redirect to='/login'/>) 
                )}
              />

              <Route 
              path='/settings' 
              render={ () => (
                loggedIn 
                ? (<Settings/>) 
                : (<Redirect to='/login'/>) 
                )}
              />

              <Route 
              path='/users' 
              render={ () => (
                loggedIn 
                ? (<Users/>) 
                : (<Redirect to='/login'/>) 
                )}
              />
              <Route 
                path='/package-types' 
                render={ () => (
                  loggedIn 
                  ? (<PackageTypes/>) 
                  : (<Redirect to='/login'/>) 
                )}
              />
              <Route path='/customRates' 
                render={ () => (
                  loggedIn 
                  ? (<CustomRates/>) 
                  : (<Redirect to='/login'/>) 
              )}
              />
              <Route 
                path='/zones' 
                render={ () => (
                  loggedIn 
                  ? (<Zones/>) 
                  : (<Redirect to='/login'/>) 
                )}
              />
              
              <Route 
                path='' 
                render={() => (<Redirect to='/'/>)}
              />
            </Switch>
          </Layout>
          </Route>
        </Switch>
      </Router>
    );
}

export default AppRouter