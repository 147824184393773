import React, {useState, useEffect} from 'react'; 
import { Col, Row } from 'react-bootstrap';
import AdminGreeting from './AdminGreeting.component';
import fetchDeliveryCounts from '../../services/fetchDeliveryCounts.service'
import MessagesOverview from './MessagesOverview.component';
import DeliveriesOverview from './DeliveriesOverview.component';
import UsersOverview from './UsersOverview.component';
import getAdmin from '../../services/fetchAdmin.service';

const REFRESH_RATE = (1000 * 30)

function Home () {

    const [admin, setAdmin] = useState(null);
    const [clientDeliveries, setClientDeliveries] = useState([]);
    const [intervalId, setIntervalId] = useState(null);

    useEffect(() => {refreshAdmin()}, [])

    useEffect(() => {
            refreshClientDeliveries()
            if (intervalId === null) {
                const interval = setInterval(() => {
                    refreshAdmin()
                    .then(refreshClientDeliveries)
                }, REFRESH_RATE)
                
                setIntervalId(interval)
            }
        }
        , [admin])

    const refreshAdmin = async () => {
        const nextAdmin = await getAdmin();
        setAdmin(nextAdmin);
    }

    const refreshClientDeliveries = async () => {
        if(admin) {
            const nextDeliveries = await fetchDeliveryCounts(admin.apiKey);
            setClientDeliveries(nextDeliveries);
        }
    }

        return (
            <React.Fragment>
                <section>
                <Row align='center'>
                    <Col>
                        <AdminGreeting adminName={(admin) ? admin.name : "Tayza Admin"}/>
                    </Col>
                </Row>
                </section>
                <br/>
                <section>
                    <Row>
                    <Col sm={12} className='mb-4'>
                        <DeliveriesOverview clientDeliveries={clientDeliveries}/>
                    </Col>
                    </Row>
                {/* <Row>
                    <Col sm={12} md={6} lg={4} className='mb-4'>
                        <UsersOverview/>
                    </Col>

                    <Col sm={12} md={6} lg={4} className='mb-4'>
                        <MessagesOverview/>
                    </Col>
                </Row> */} 
                </section> 


            </React.Fragment>
        )
}

export default Home;