import React, {Component} from 'react'; 
import { Button, InputGroup, FormControl, ButtonGroup } from 'react-bootstrap';

import deleteCustomRate from '../../services/deleteCustomRate.service';

class CustomRatesListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rate: props.rate,
        }
    }

    async componentDidUpdate(prevProps) {
        if(prevProps.rate != this.props.rate) {
            this.setState({
                rate: this.props.rate,
            })
        }
    }

    removeCustomRate = () => {
        const apiKey = this.props.apiKey;
        const userId = this.props.userId;
        const zoneId = this.props.rate.zone._id;

        deleteCustomRate(apiKey, userId, zoneId)
        .then(() => {
            this.props.refreshRates();
        })
        .catch(err => {
            console.log(err)
        })
    }

    render() { 
        const rate = this.state.rate;

        return (
            <>
            {
                <tr>
                    <td>
                        {this.props.companyName}
                    </td>
                    <td>
                        {rate.zone.title}
                    </td>
                    <td>
                        $ {rate.price}
                    </td>
                    <td align='right'>
                        <ButtonGroup block>
                        <Button variant='secondary' onClick={this.removeCustomRate}>Delete</Button>
                        </ButtonGroup>
                    </td>
                </tr> 
            }
            </>                    
        )
    }
}

export default CustomRatesListItem;