const axios = require('axios').default;

const createPackageType = async (apiKey, fields) => {
  const packageTypesEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/package-types/`
  const result = await axios.post(
    packageTypesEndpoint,
    {packageType: fields},
    {headers: {'X-API-Key': apiKey}}
  );
  
  const newPackageType = result.data.data;
  return newPackageType;
}

export default createPackageType;