const axios = require('axios').default;

const deletePackageType = async (apiKey, id) => {
  const packageTypesEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/package-types/${id}`
  const result = await axios.delete(packageTypesEndpoint, {
      headers: {'X-API-Key': apiKey}
    }
  );
  
  const deletedPackageType = result.data.data;
  return deletedPackageType;
}

export default deletePackageType;