const axios = require('axios').default;

const resetUserPassword = async (id, password) => {
    const resetPasswordEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/users/reset-password/${id}`
    const newPassword = {newPassword: password}

    try {
      const result = await axios.post(
          resetPasswordEndpoint,
          newPassword,
          {withCredentials: true}
        ); 

        return true;
    }
    catch (err) {
        console.log(err)
        return false; 
    }
  
  }
  
  export default resetUserPassword;