const axios = require('axios').default;

const changeAdminInfo = async (fields) => {
  const adminEditEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/admin`
  const result = await axios.put(
    adminEditEndpoint,
    {admin:fields},
    {withCredentials: true}
  );
}

export default changeAdminInfo;