const axios = require('axios').default;

const createCustomRate = async (apiKey, companyName, zoneTitle, price) => {
    const getUserCompanyNameEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/users/company/${companyName}`;
    const user = await axios.get(getUserCompanyNameEndpoint);

    const getZoneByZoneTitleEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/zones/zoneTitle/${zoneTitle}`;
    const zone = await axios.get(getZoneByZoneTitleEndpoint);

    const createCustomRateEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/zones/customRates`;
    const result = await axios.post(
    createCustomRateEndpoint,
    {
        userId: user.data._id,
        zoneId: zone.data._id,
        price: price,
        companyName: companyName
    },
    {headers: {'X-API-Key': apiKey}}
  );
  
  const rate = result.data;
  return rate;

}

export default createCustomRate;