import React, {useState, useEffect} from 'react'; 
import { Card, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';


function DeliveriesOverview ({clientDeliveries}) {
    const [totalDeliveries, setTotalDeliveries] = useState(0);
    
    useEffect(() => {
    let total = 0;

            if (clientDeliveries.length === 1) {
                total = clientDeliveries[0].total;
            }
            if (clientDeliveries.length > 1) {
                  total = clientDeliveries.reduce((prev, current) => {
                    let prevTotal = (prev.total) ? prev.total : prev;
                    let currentTotal = current.total;                  
                    return prevTotal + currentTotal;
                });
                } 
                setTotalDeliveries(total);
    }, [clientDeliveries])

        return (
            <Card bg="primary" text="white" style={{width: '100%', height:'100%'}}>
                <Card.Header>
                    <Card.Title>
                        Summary of Deliveries
                    </Card.Title>
                </Card.Header>
                <Card.Body style={{padding: 0}}>
                    <Table striped style={{backgroundColor:'white', margin: 0, fontSize: '0.8em'}}>
                        <thead >
                            <tr>
                                <th>
                                    Company Name
                                </th>
                                <th>
                                    Last Submitted
                                </th>
                                <th>
                                    Current Deliveries
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            (clientDeliveries) 
                            ? 
                                clientDeliveries.map(clientDelivery => (
                                    <tr>
                                        <td>
                                            {clientDelivery.companyName}
                                        </td>
                                        <td>
                                            {new Date(Date.parse(clientDelivery.latest)).toString()}
                                        </td>
                                        <td>
                                            {clientDelivery.total}
                                        </td>
                                    </tr>
                                    )
                                )
                            : 
                                (
                                <tr>
                                    <td>
                                        <i text-align="center">There is nothing to display yet.</i>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                )
                            }


                        </tbody>
                    </Table>
                </Card.Body>
                <Card.Footer>
                    <Card.Text>
                        Total current deliveries: {totalDeliveries}
                    </Card.Text>
                </Card.Footer>
            </Card>
        )
}

DeliveriesOverview.propTypes = {
    clientDeliveries: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default DeliveriesOverview;