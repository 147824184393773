import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import AdminListHeader from './AdminListHeader.component';
import AdminListItem from './AdminListItem.component';

function AdminList(props) {
    const [admin, setStateAdmin] = useState(null);
    const [admins, setStateAdmins] = useState([]);

    useEffect(() => {
        if (admins != props.admins || admin != props.admin) {
            setStateAdmins(sortAdminsByName(props.admins));
            setStateAdmin(props.admin);
        }
    });

    const sortAdminsByName = (admins) => {
        return admins.sort((a, b) => a.name.localeCompare(b.name));
    }

    let listItems = [];

    admins.forEach(admin => {
        let listItem = (
            <AdminListItem
                admin={admin}
                isCurrentAdmin={props.isCurrentAdmin}
                refreshAdmins={props.refreshAdmins}
            />
        );

        listItems.push(listItem);
    });

    return (
        <Table striped>
            <AdminListHeader></AdminListHeader>
            <tbody>
                {listItems}
            </tbody>
        </Table>
    )

}

export default AdminList;
