import React, { useState } from 'react';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import Layout from '../_common/Layout.component';
import doLogin from '../../services/doLogin.service';
import PropTypes from 'prop-types';

function Login({ checkIsLoggedIn }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const getEmail = e => {
        setEmail(e.target.value);
    }

    const getPassword = e => {
        setPassword(e.target.value);
    }

    const doDoLogin = async e => {
        e.preventDefault()
        doLogin(email, password).then(() => {
            checkIsLoggedIn();
        }) 
    }

    return (
        <Layout>
            <Row className="align-items-center justify-content-center mt-4" >
                <Col xs={12} sm={6} md={4} lg={3} xl={2}>
                    <Card>
                        <Card.Img variant="top" src={require("../../assets/logo.png")} />
                        <Card.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Control type="email" placeholder="Admin email" onChange={getEmail} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control type="password" placeholder="password" onChange={getPassword} />
                                </Form.Group>
                                <Button type="submit" variant='secondary' block onClick={doDoLogin}>Log In</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
}

Login.propTypes = {
    checkIsLoggedIn: PropTypes.func.isRequired
}

export default Login;
