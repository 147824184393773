import React, { useState, useEffect } from 'react';

import PackageTypeListItem from './PackageTypeListItem.component';
import PropTypes from 'prop-types';

import {Row, Col} from 'react-bootstrap'
function PackageTypeList({packageTypes, refreshPackageTypes, apiKey}) {
  return (
    <>
      <Row xs={1} lg={2} className="g-4">
        {packageTypes.map(packageType => 
          <Col className="mb-4">
            <PackageTypeListItem 
              packageType={packageType}
              refreshPackageTypes={refreshPackageTypes}
              apiKey={apiKey}
            />
          </Col>
        )}
      </Row>
    </>
  )
}

PackageTypeList.propTypes = {
  packageTypes: PropTypes.array.isRequired,
  refreshPackageTypes: PropTypes.func.isRequired,
  apiKey: PropTypes.string.isRequired
}

export default PackageTypeList;