import React, { useState, useEffect}  from 'react'; 
import {Row, Col} from 'react-bootstrap'
import ZoneList from './ZoneList.component'
import fetchAdmin from '../../services/fetchAdmin.service';
import fetchZones from '../../services/fetchZones.service';

function Zones() {

    const [admin, setAdmin] = useState({});
    const [zones, setZones] = useState([]);

    useEffect(() => { refreshAdmin()
        .then(() => {refreshZones()})
    }, []);


    const refreshAdmin = async () => {
        const admin = await fetchAdmin();
        setAdmin(admin);
    }

    const refreshZones = async () => {
        const apiKey = admin.apiKey;
        let zones = await fetchZones(apiKey);
        if (zones) {
            zones = zones.sort((a,b) => {
                if (a.zone > b.zone) return 1;
                else if (a.zone < b.zone) return -1;
                else {
                    if (a.subzone > b.subzone) return 1;
                    else return -1;
                }
            })
        }
        
        setZones(((zones) ? zones : []));
    }


    return (
      <React.Fragment>
        <br/>
        <br/>
        <Row>
          <Col>
            <h1>Zone Manager</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <ZoneList 
              zones={zones}
              apiKey={(admin) ? admin.apiKey : null}
              refreshZones={refreshZones}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
}
export default Zones;