import React from 'react'; 

function UserListHeader (){

        return (
            <thead>
                <tr>
                    <th>
                        <strong>Company Name</strong>
                    </th>
                    <th>
                        <strong>Email</strong>
                    </th>
                    <th>
                        <strong>UID</strong>
                    </th>
                    <th>
                        <strong>Joined</strong>
                    </th>
                    <th>
                        <strong>Addresses</strong>
                    </th>
                    <th/> 
                    <th/>
                </tr>
            </thead>
        )
}

export default UserListHeader;