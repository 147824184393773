import React, {Component} from 'react'; 
import { Form, Button, Col, Row, ButtonGroup } from 'react-bootstrap';

import createCustomRate from '../../services/createCustomRate.service';

class UserCreator extends Component {
    constructor(props){
        super(props);
        this.state = {
            companyName: '',
            zoneTitle: '',
            price: 0, 
        }
    }

    updateValue = (event) => {
        const key = event.target.id;
        const value = event.target.value;

        this.setState({[key]:value});
    }


    doCreate = (event) => {
        event.preventDefault();
        createCustomRate(this.props.apiKey, this.state.companyName, this.state.zoneTitle, this.state.price)
        .then(() => {
            this.props.refreshRates()
        })
    }

    validateFields = () => {
        const isValid = (
            this.state.companyName !== '' && 
            this.state.zoneTitle !== '' && 
            this.state.price !== 0
        )
        return isValid
    }

    render() {
        return (
            <Form>
                <Row>
                    <Col>
                        <Form.Group controlId="companyName">
                            <Form.Control 
                            as="select" 
                            placeholder="Select Company"
                            value={this.state.companyName}
                            onChange={this.updateValue}
                            custom>
                                <option>Select company...</option>
                                { 
                                    (this.props.companyNames) 
                                    ? this.props.companyNames.map(
                                            companyName => <option>{companyName}</option>
                                        )
                                    : <option>loading companies...</option>
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="zoneTitle">
                            <Form.Control 
                            as="select" 
                            placeholder="Select Zone"
                            value={this.state.zoneTitle}
                            onChange={this.updateValue}
                            custom>
                                <option>Select zone...</option>
                                { 
                                    (this.props.zones) 
                                    ? this.props.zones.map(
                                            zone => <option id={zone._id}>{zone.title}</option>
                                        )
                                    : <option>loading zones..</option>
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="price" xs='6'>
                            <Form.Control 
                                type="text" 
                                placeholder="Price" 
                                value={this.state.price}
                                onChange={this.updateValue}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md="auto">
                        <Button 
                        variant="primary" 
                        type="submit"
                        onClick={this.doCreate}
                        disabled={!this.validateFields()}
                        >
                            Create
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default UserCreator;