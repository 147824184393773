const axios = require('axios').default;

const createUser = async (email, username, password) => {
  const usersEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/admins/`
  const result = await axios.post(
    usersEndpoint,
    {
        admin: {
            email: email,
            name: username,
            password: password,
        }

    },
    {withCredentials:true}
  );
  
  const user = result.data;
    return user
}

export default createUser;