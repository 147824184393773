import axios from "axios";

const doLogout = async () => {

    const loginEndpoint = process.env.REACT_APP_AUTH_ENDPOINT + '/admin/login'
    try {
        let response = await axios.delete(
            loginEndpoint,
            {withCredentials: true}
        )
        return true;
    }
        
    catch (error) {
        alert(error);
        return false;
    }
}

export default doLogout;