import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav} from 'react-bootstrap';
import Navlink from './Navlink.component';
import LogoutLink from './LogoutLink.component'

function TayzaNav ( {checkIsLoggedIn} ) {
  const links = { 
    'Admins': '/admins',
    'Users': '/users',
    'Zones': '/zones',
    'Custom Rates': '/customRates',
    'Package Types': '/package-types',
    //Orders: '/orders',
    //Messages: '/messages',
    'Settings': '/settings'
  };
      
  let navBarPages = [];
  
  Object.keys(links).forEach(key => {
    navBarPages.push(
    <Navlink 
      key={key} 
      href={links[key]}
    >
      {key}
    </Navlink>
    )    
  });

  return (
    <Navbar sticky="top" bg="primary" variant="dark" expand="lg">
      <Navbar.Brand href="/">
        <img
          src={require('../../assets/logo.png')}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt="Tayza T"
        /> 
        <em>Administrator</em>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="justify-content-end" style={{width:"100%"}}>
          {navBarPages}
          <LogoutLink checkIsLoggedIn={checkIsLoggedIn}/>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

TayzaNav.propTypes = {
  checkIsLoggedIn: PropTypes.func.isRequired
}

export default TayzaNav;
