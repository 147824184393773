import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';

function Layout( {children} ) {
    return (
        <Container fluid>
            { children }
        </Container>
    )
}

Layout.propTypes = {
    children: PropTypes.element.isRequired
}

export default Layout;
