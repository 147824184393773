import React, {useState, useEffect} from 'react'; 
import fetchUsers from '../../services/fetchUsers.service';
import UserList from './UserList.component';
import UserCreator from './UserCreator.component';
import {Row, Col} from 'react-bootstrap';
import fetchTeams from '../../services/fetchTeams.service';

function Users () {
    
    const [users, setUsers] = useState([]);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        refreshUsers();
        refreshTeams();
    }, [])

    const refreshUsers = async () => {
        const users = await fetchUsers();
        setUsers(users);
    }

    const refreshTeams = async () => {
        const teams = await fetchTeams();
        setTeams(teams);
    }
       
        return (
            <React.Fragment>
                <br/>
                <br/>
                <Row>
                    <Col>
                        <h1>Create User</h1>
                        <UserCreator 
                        refreshUsers={refreshUsers}
                        />
                    </Col>
                </Row>
                <Row>
                    <hr/>
                </Row>
                <Row>
                    <Col>
                        <h1>User List</h1>
                        <UserList 
                            users={users}
                            refreshUsers={refreshUsers}
                            teams={teams}
                        />
                    </Col>
                </Row>
            </React.Fragment>
            
        )
}

export default Users;