const axios = require('axios').default;

const suspendUser = async (id) => {
  const userSuspendEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/users/suspend/${id}`
  const result = await axios.post(
    userSuspendEndpoint,
    {},
    {withCredentials: true}
  );
}

export default suspendUser;