import React from 'react';

function AdminListHeader() {
    return (
        <thead>
            <tr>
                <th>
                    <strong>Username</strong>
                </th>
                <th>
                    <strong>Email</strong>
                </th>
                <th>
                    <strong>UID</strong>
                </th>
                <th>
                    
                </th>
            </tr>
        </thead>
    )
}

export default AdminListHeader;