import React, { useState, useEffect } from 'react';
import fetchAdmins from '../../services/fetchAdmins.service';
import AdminList from './AdminList.component';
import AdminCreator from './AdminCreator.component';
import {Row, Col} from 'react-bootstrap'

import fetchAdmin from '../../services/fetchAdmin.service';

function Admins() {
    const [admin, setAdmin] = useState(null);
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        refreshAdmin();
        refreshAdmins();
    }, []);

    const refreshAdmin = async () => {
        const admin = await fetchAdmin();
        setAdmin(admin);
    }

    const refreshAdmins = async () => {
        const admins = await fetchAdmins();
        setAdmins(admins);
    }

    return (
        <React.Fragment>
            <br/>
            <br/>
            <Row>
                <Col>
                    <h1>Create Admin</h1>
                    <AdminCreator refreshAdmins={refreshAdmins}/>
                </Col>
            </Row>
            <Row>
                <hr/>
            </Row>
            <Row>
                <Col>
                    <h1>Admin List</h1>
                    <AdminList 
                        admin={admin}
                        admins={admins}
                        refreshAdmins={refreshAdmins}
                    />
                </Col>
            </Row>
        </React.Fragment>
        
    );
}

export default Admins;