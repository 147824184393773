const axios = require('axios').default;

const loginAsUser = async (id) => {
  const userLoginEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/users/login-as/${id}`
  const clientAppUrl = process.env.REACT_APP_CLIENT_APP
  try {
    const result = await axios.post(
        userLoginEndpoint,
        {},
        {withCredentials: true}
      );

    window.open(clientAppUrl, "LoginWindow");

  }
  catch (err) {
      console.log(err)
  }

}

export default loginAsUser;