import React  from 'react'; 
import { Jumbotron } from 'react-bootstrap';
import PropTypes from 'prop-types';

function GreetingMessage ({adminName}) {
        return (
            <React.Fragment>
                <Jumbotron align="center" className="mt-4 mb-0">
                <h1>Welcome back, {adminName}</h1>
                <h3>Here's what's happening today.</h3>
                </Jumbotron>
            </React.Fragment>
        )
}

GreetingMessage.propTypes = {
    adminName: PropTypes.string.isRequired
}

export default GreetingMessage;