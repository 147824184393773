const axios = require('axios').default;

const updateZone = async (apiKey, zoneId, fields) => {
  const zonesEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/zones/${zoneId}`
  const result = await axios.put(zonesEndpoint, {zone: fields}, {
      headers: {'X-API-Key': apiKey}
    }
  );
  
  const zone = result.data.data;
  return zone;
}

export default updateZone;