const axios = require('axios').default;

const assignTeam = async (user, nextAddress, teamObject) => {

  const currentAddresses = user.address;

  const nextAddresses = currentAddresses.map((address) => {
    if (JSON.stringify(address) === JSON.stringify(nextAddress)) {
      return {
        ...address,
        onfleetTeam: teamObject
      }
    }
    else {
      return address
    }
  })

  //do update operation
  const userEditEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/users/${user._id}`
  const result = await axios.put(
    userEditEndpoint,
    {user: {address: nextAddresses}},
    {withCredentials: true}
  );
}

export default assignTeam;