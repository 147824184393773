import React, {useState} from 'react'; 
import { Button, InputGroup, FormControl, ButtonGroup } from 'react-bootstrap';
import createZone from '../../services/createZone.service';
import PropTypes from 'prop-types';

function AddZone ({refreshZones, apiKey}) {

    const [title, setTitle] = useState('');
    const [zone, setZone] = useState('');
    const [subzone, setSubzone] = useState('');
    const [price, setPrice] = useState('');
    const [editing, setEditing] = useState(false);

    const toggleEditing = () => {
        setTitle('');
        setZone('');
        setSubzone('');
        setPrice('');
        setEditing(!editing);
    }

    const cacheEdits = (event) => {
        const key = event.target.id;
        const value = (key === 'price') ? Number(event.target.value) : event.target.value;
        switch(key) {
            case 'title': 
                setTitle(value);
                break;
            case 'zone': 
                setZone(value);
                break;
             case 'subzone': 
                setSubzone(value);
                break;
            case 'price': 
                if(value <= 0)setPrice('')
                else setPrice(value);
                break;
            case 'editing': 
                setEditing(value);
        }  
    }

   const postZone = () => {
        const fields = {
            title: title,
            zone: zone,
            subzone: subzone,
            price: price
        }
        createZone(apiKey, fields)
        .then(() => {
            refreshZones();
            toggleEditing();
        })
        .catch(err => {
            console.log(err)
        })
    }
 
        //const editing = editing;

        return (
            <>
            {
                (editing)
                ?
                <tr>
                    <td>
                        <InputGroup>
                            <FormControl
                                id='title'
                                placeholder='title'                       
                                value={title}
                                onChange={cacheEdits}
                            />
                        </InputGroup>
                    </td>
                    <td> 
                        <InputGroup>
                            <FormControl
                                id='zone'
                                placeholder='zone'
                                value={zone}
                                onChange={cacheEdits}
                            />
                            <InputGroup.Prepend>
                                <InputGroup.Append>
                                    <InputGroup.Text> - </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup.Prepend>
                            <FormControl
                                id='subzone'
                                placeholder='sub-zone'
                                value={subzone}
                                onChange={cacheEdits}
                            />
                        </InputGroup>
                    </td>
                    <td> 
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type='number'
                                id='price'
                                placeholder='price'
                                value={price}
                                onChange={cacheEdits}
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            />
                        </InputGroup>
                    </td>
                    <td/>
                    <td align="right">
                        <ButtonGroup block >
                            <Button onClick={toggleEditing}>Cancel</Button>
                            <Button variant='secondary' onClick={postZone}>Create</Button>
                        </ButtonGroup>
                    </td>
                </tr>
                :
                <tr>
                    <td>
                        <Button variant='secondary' onClick={toggleEditing}>Add Zone</Button>
                    </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                </tr>
            }
            </>                    
        )
}

AddZone.propTypes = {
    apiKey: PropTypes.string.isRequired,
    refreshZones: PropTypes.func.isRequired
}

export default AddZone;