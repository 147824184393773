const axios = require('axios').default;

const deleteAdmin = async (id) => {
  const userSuspendEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/admins/${id}`
  const result = await axios.delete(
    userSuspendEndpoint,
    {withCredentials: true}
  );
}

export default deleteAdmin;