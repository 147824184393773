import React, {Component} from 'react'; 
import {Row, Col} from 'react-bootstrap'

import CustomRatesList from './CustomRatesList.component'
import AddCustomRate from './AddCustomRate.component';

import fetchAdmin from '../../services/fetchAdmin.service';
import fetchRates from '../../services/fetchRates.service';
import fetchUsers from '../../services/fetchUsers.service';
import fetchZones from '../../services/fetchZones.service';


class CustomRates extends Component {
    constructor() {
        super();
        this.state = {
            admin: null,
            rates: [],
            zones: [],
            companyNames: []
        }
    }
    componentDidMount() {
        this.refreshAdmin().then(() => {
            this.refreshRates();
            this.refreshUsers();
            this.refreshZones();
        })
    }

    refreshAdmin = async () => {
        const admin = await fetchAdmin();
        this.setState({admin: admin});
    }

    refreshRates = async () => {
        const apiKey = this.state.admin.apiKey;
        let rates = await fetchRates(apiKey);
        
        if (rates) {
            rates = rates.sort((a,b) => {
                if (a.companyName > b.companyName) return 1;
                else return -1;
            })
        }
        
        this.setState({rates: (rates) ? rates : []})
    }

    refreshZones = async () => {
        const apiKey = this.state.admin.apiKey;
        let zones = await fetchZones(apiKey);
        if (zones) {
            zones = zones.sort((a,b) => {
                if (a.zone > b.zone) return 1;
                else if (a.zone < b.zone) return -1;
                else {
                    if (a.subzone > b.subzone) return 1;
                    else return -1;
                }
            })
        }
        
        this.setState({zones: (zones) ? zones : []})
    }

    refreshUsers = async () => {
        const users = await fetchUsers();
        const companyNames = users.map(user => {
            return user.companyName;
        })
        this.setState({companyNames: companyNames})
    }

    render() {
        return (
            <React.Fragment>
                <br/>
                <br/>
                <Row>
                    <Col>
                        <h1>Custom Rate Manager</h1>
                        <h2>Add Custom Rate</h2>
                        <AddCustomRate
                            apiKey={(this.state.admin) ? this.state.admin.apiKey : null} 
                            refreshRates={this.refreshRates}
                            companyNames={this.state.companyNames}
                            zones={this.state.zones}
                        />
                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col>
                    <CustomRatesList 
                            rates={this.state.rates}
                            apiKey={(this.state.admin) ? this.state.admin.apiKey : null}
                            refreshRates={this.refreshRates}
                    />
                    </Col>
                </Row>
            </React.Fragment>
            
        )
    }
}

export default CustomRates;