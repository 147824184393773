import React from 'react'; 
import Card from 'react-bootstrap/Card';

function InDev() {
    return (
        <React.Fragment>
            <Card style={{width: '30vw', margin:'5% 35% 5% 35%'}}>
                <Card.Body>
                    <Card.Title>Not Ready Yet!</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                        This feature is currently still in development, 
                        and will be released as soon as resources are available
                    </Card.Subtitle>
                    <Card.Text>
                        For any questions regarding feature updates or feedback on app functionality, 
                        feel free to contact Caleb Caldwell at <a href='mailto:dev@tayza.io'>dev@tayza.io</a>
                    </Card.Text>
                    <Card.Link className='btn btn-secondary' href='/'>Got it</Card.Link>
                </Card.Body>
            </Card>
        </React.Fragment>
    )

}

export default InDev;