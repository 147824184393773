const axios = require('axios').default;

const fetchRates = async (apiKey) => {
  const ratesEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/zones/customRates`
  const result = await axios.get(ratesEndpoint,
    {headers: {'X-API-Key': apiKey}}
  );
  
  const rates = result.data.data;
  return rates;
}

export default fetchRates;