import React, {useState, useEffect} from 'react'; 
import PropTypes from 'prop-types';
import { Button, InputGroup, FormControl, ButtonGroup } from 'react-bootstrap';
import updateZone from '../../services/updateZone.service';
import deleteZone from '../../services/deleteZone.service';

function ZoneListItem ({zone, apiKey, refreshZones }) {
    const [editingZone, setEditingZone] = useState({
                 title: zone.title,
                 zone: zone.zone,
                 subzone: zone.subzone,
                 price: zone.price,
                 postalCodes: zone.postalCodes
             });
    const [editing, setEditing] = useState(false);
    const [newPostalCode, setNewPostalCode] = useState('');

    useEffect (() => {
           setEditingZone({
                    title: zone.title,
                    zone: zone.zone,
                    subzone: zone.subzone,
                    price: zone.price,
                    postalCodes: zone.postalCodes
                });
        
    }, [zone])

    const toggleEditing = () => {
        setEditing(!editing);
        setEditingZone({
                 title: zone.title,
                 zone: zone.zone,
                 subzone: zone.subzone,
                 price: zone.price,
                 postalCodes: zone.postalCodes
             });
             setNewPostalCode('');
    }
    const cacheEdits = (event) => {
        const key = event.target.id;
        const value = (key === 'price') ? Number(event.target.value) : event.target.value;
        let nextEditingZone = {...editingZone};
        nextEditingZone[key] = value;
        if(key === 'price' && value <= 0){
            nextEditingZone[key] = ''
        }
        setEditingZone (nextEditingZone);        
    }

    const cachePostalCode = (event) => {
        const value = event.target.value;
        setNewPostalCode(value)
    }

    const addPostalCode = () => {
        const tempNewPostalCode = newPostalCode;
        let nextPostalCodes = [...editingZone.postalCodes, tempNewPostalCode];
        let nextEditingZone = {...editingZone, postalCodes: nextPostalCodes};

        setEditingZone(nextEditingZone);
        setNewPostalCode('');
    }

     const removePostalCode = (event) => {
        const index = event.target.id;
        let nextPostalCodes = [...editingZone.postalCodes]
        nextPostalCodes.splice(index, 1);
        let nextEditingZone = {...editingZone, postalCodes: nextPostalCodes};

        setEditingZone(nextEditingZone);
    }

    const postEdits = () => {
        const tempApiKey = apiKey;
        const zoneId = zone._id;
        const fields = editingZone;

        updateZone(tempApiKey, zoneId, fields)
        .then( () => {
            refreshZones()
            setEditing(false);
            setNewPostalCode('');
         })
        .catch(err => {
            console.log(err)
        })
    }

    const removeZone = () => {
        const zoneId = zone._id;

        deleteZone(apiKey, zoneId)
        .then( () => {
            refreshZones();
        })
        .catch(err => {
            console.log(err)
        })
    }

        return (
            <>
            {
                (editing)
                ?
                <tr>
                    <td>
                        <InputGroup>
                            <FormControl
                                id='title'
                                value={editingZone.title}
                                onChange={cacheEdits}
                            />
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                            <FormControl
                                id='zone'
                                value={editingZone.zone}
                                onChange={cacheEdits}
                            />
                            <InputGroup.Prepend>
                                <InputGroup.Append>
                                    <InputGroup.Text> - </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup.Prepend>
                            <FormControl
                                id='subzone'
                                value={editingZone.subzone}
                                onChange={cacheEdits}
                            />
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type='number'
                                id='price'
                                value={editingZone.price}
                                onChange={cacheEdits}
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            />
                        </InputGroup>
                        
                    </td>
                    <td>
                        {
                            editingZone.postalCodes.map((code,index) => {
                                return (
                                <div style={{display: 'inline-block'}} className='m-1'>
                                    <InputGroup >
                                        <InputGroup.Prepend>
                                        <InputGroup.Text>{code}</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Button id={index} onClick={removePostalCode}>X</Button>
                                    </InputGroup>
                                </div>
                                )
                            })
                        }
                        <div style={{display: 'inline-block'}} className='m-1'>
                            <InputGroup>
                                <FormControl 
                                placeholder='new' 
                                value={newPostalCode}
                                onChange={cachePostalCode}
                                />
                                <Button onClick={addPostalCode}>+</Button>
                            </InputGroup>
                        </div>
                    </td>
                    <td align='right'>
                        <ButtonGroup block>
                        <Button onClick={toggleEditing}>Cancel</Button>
                        <Button variant='secondary' onClick={postEdits}>Save</Button>
                        </ButtonGroup>

                    </td>
                </tr> 
                :
                <tr>
                    <td>
                        {zone.title}
                    </td>
                    <td>
                        {`${zone.zone} - ${zone.subzone}`}
                    </td>
                    <td>
                        $ {zone.price}
                    </td>
                    <td>
                        {zone.postalCodes.join(', ')}
                    </td>
                    <td align='right'>
                        <ButtonGroup block>
                        <Button onClick={toggleEditing}>Edit</Button>
                        <Button variant='secondary' onClick={removeZone}>Delete</Button>
                        </ButtonGroup>
                    </td>
                </tr> 
            }
            </>                    
        )
}

ZoneListItem.propTypes = {
    zone: PropTypes.object.isRequired,
    apiKey: PropTypes.string.isRequired,
    refreshZones: PropTypes.func.isRequired
}

export default ZoneListItem;