const axios = require('axios').default;

const fetchAdmins = async () => {
  const usersEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/admins/`
  const result = await axios.get(
    usersEndpoint,
    {withCredentials:true}
  );
  
  const users = result.data;
  return users
}

export default fetchAdmins;