import axios from "axios"

const changePassword = async (oldPassword, newPassword) => {
    const passwords = {
        admin: {
            oldPassword,
            newPassword
        }
    }

    const loginEndpoint = process.env.REACT_APP_AUTH_ENDPOINT + '/admin/password'
    try {
        let response = await axios.put(
            loginEndpoint, 
            passwords,
            {withCredentials: true}
        )
            return true;
    }
    
    catch (err) {
        alert(err);
        return false;
    }
}

export default changePassword;