const axios = require('axios').default;

const createZone = async (apiKey, fields) => {
  const zonesEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/zones/`
  const result = await axios.post(
      zonesEndpoint,
      {zone: fields},
      {headers: {'X-API-Key': apiKey}}
  );
  
  const zone = result.data.data;
  return zone;
}

export default createZone;