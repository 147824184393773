const axios = require('axios').default;

const fetchDeliveries = async (apiKey) => {
  const deliveriesEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/deliveries/count`
  const result = await axios.get(
    deliveriesEndpoint,
    { headers: {'X-API-Key': apiKey} }
    
  );
  
  const deliveries = result.data.data;
  return deliveries
}

export default fetchDeliveries;