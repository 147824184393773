import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Col, Row } from 'react-bootstrap';
import createAdmin from '../../services/createAdmin.service';

function AdminCreator({ refreshAdmins }) {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const doCreate = e => {
        e.preventDefault()
        createAdmin(email, username, password).then(() => {
            refreshAdmins()
        })
        .catch(err => alert(err));
    }

    const validateFields = () => {
        const isValid = (
            username !== '' &&
            email !== '' &&
            password !== ''
        )
        return isValid
    }

    return (
        <Form>
            <Row>
                <Col>
                    <Form.Group controlId="username">
                        <Form.Control
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="email">
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="password">
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md="auto">
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={doCreate}
                        disabled={!validateFields()}
                    >
                        Create
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

AdminCreator.propTypes = {
    refreshAdmins: PropTypes.func.isRequired
}

export default AdminCreator;
