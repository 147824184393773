import React, { useState, useEffect } from 'react';

import AddPackageType from './AddPackageType.component';
import PackageTypeList from './PackageTypeList.component';

import fetchAdmin from '../../services/fetchAdmin.service';
import fetchPackageTypes from '../../services/fetchPackageTypes.service';

import {Row, Col} from 'react-bootstrap'
function PackageTypes() {

  const [admin, setAdmin] = useState({});
  const [packageTypes, setPackageTypes] = useState([]);

  useEffect(() => { 
    refreshAdmin()
    .then(refreshPackageTypes)
  }, []);

  const refreshAdmin = async () => {
    const nextAdmin = await fetchAdmin();
    setAdmin(nextAdmin);
  }

  const refreshPackageTypes = async () => {
    const apiKey = admin.apiKey;
    let nextPackageTypes = await fetchPackageTypes(apiKey);
    if (nextPackageTypes) {
      console.log(nextPackageTypes)
      nextPackageTypes.sort((a,b) => b.priceMultiplier - a.priceMultiplier);
      setPackageTypes(nextPackageTypes);
    } else {
      setPackageTypes([])
    }
  }

  return (
    <>
      <br/>
      <br/>
      <Row>
        <Col>
          <h1>Package Types Manager</h1>
        </Col>
      </Row>
      <hr/>
      <PackageTypeList
        packageTypes={packageTypes}
        refreshPackageTypes={refreshPackageTypes}
        apiKey={(admin) ? admin.apiKey : null}
      />
      <hr/>
      <AddPackageType
        refreshPackageTypes={refreshPackageTypes}
        apiKey={(admin) ? admin.apiKey : null}
      />
    </>
  )
}

export default PackageTypes;