import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import deleteAdmin from '../../services/deleteAdmin.service';

function AdminListItem({admin, isCurrentAdmin, refreshAdmins}) {
    // const [admin] = useState(admin);

    const doDeleteAdmin = () => {
        deleteAdmin(admin._id).then(() => {
            refreshAdmins();
        })
    }   

    return (   
        <tr>
            <td>
                {admin.name}
            </td>
            <td>
                {admin.email}
            </td>
            <td>
                {admin._id}
            </td>
            <td align='right'>
                <Button
                    variant="secondary"
                    onClick={doDeleteAdmin}
                    disabled={isCurrentAdmin}
                >Delete</Button>
            </td>
        </tr>
    );
}

AdminListItem.propTypes = {
    admin: PropTypes.element.isRequired,
    isCurrentAdmin: PropTypes.func.isRequired,
    refreshAdmins: PropTypes.func.isRequired
}

export default AdminListItem;
