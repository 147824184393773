import React from 'react';
import { Nav } from 'react-bootstrap';

import doLogout from '../../services/doLogout.service'
import PropTypes from 'prop-types';

function LogoutLink({ checkIsLoggedIn }) {

    const logOut = async () => {
        const logout = await doLogout();
        if (logout) checkIsLoggedIn();
    }

    return (
        <Nav.Link onClick={logOut}>
            Log out
        </Nav.Link>
    );
}

LogoutLink.propTypes = {
    checkIsLoggedIn: PropTypes.func.isRequired
}

export default LogoutLink;
