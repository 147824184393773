const axios = require('axios').default;

const fetchUsers = async () => {
  const usersEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/users/`
  const result = await axios.get(
    usersEndpoint,
    {withCredentials:true}
  );
  
  const users = result.data;
    return users
}

export default fetchUsers;