import React, {useState} from 'react'; 
import { Form, Button, Col, Row, ButtonGroup } from 'react-bootstrap';
import createUser from '../../services/createUser.service';
import PropTypes from 'prop-types';

function UserCreator ({teams, refreshUsers }) {

    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newTeam, setNewTeam] = useState(false);
    const [existingTeam, setExistingTeam] = useState(false);
    const [clearTeam, setClearTeam] = useState(true);
 
    const updateValue = (event) => {
        const key = event.target.id;
        const value = event.target.value;
        switch (key) {
            case 'companyName': 
                setCompanyName(value);
                break;
            case 'email': 
                setEmail(value);
                break;
            case 'password': 
                setPassword(value);
                break;
        }
    }


    const doCreate = (event) => {
        event.preventDefault();
        createUser(email, companyName, password)
        .then(() => {
        setEmail('');
        setCompanyName('');
        setPassword('');
        refreshUsers();
        })
    }

    const validateFields = () => {
        const isValid = (
            companyName !== '' && 
            email !== '' && 
            password !== ''
        )
        return isValid
    }

        return (
            <Form>
                <Row>
                    <Col>
                        <Form.Group controlId="companyName">
                            <Form.Control 
                            type="text" 
                            placeholder="Company Name" 
                            value={companyName}
                            onChange={updateValue}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="email">
                            <Form.Control 
                            type="email" 
                            placeholder="Email" 
                            value={email}
                            onChange={updateValue}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="password" xs='6'>
                            <Form.Control 
                                type="password" 
                                placeholder="Password" 
                                value={password}
                                onChange={updateValue}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md="auto">
                        <Button 
                        variant="primary" 
                        type="submit"
                        onClick={doCreate}
                        disabled={!validateFields()}
                        >
                            Create
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
}

UserCreator.propTypes = {
    teams: PropTypes.object.isRequired,
    refreshUsers: PropTypes.func.isRequired
}

export default UserCreator;