import React, {Component} from 'react'; 
import { Table, Button } from 'react-bootstrap';

import CustomRatesListItem from './CustomRatesListItem.component';

class CustomRatesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rates: []
        }
    }


    componentDidUpdate(prevProps) {
        if(prevProps.rates != this.props.rates) {
            this.setState({
                rates: this.props.rates,
            })
        }
    }

    render() { 
        const rates = this.state.rates;

        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>
                            <strong>Company Name</strong>
                        </th>
                        <th>
                            <strong>Zone Name</strong>
                        </th>
                        <th>
                            <strong>Price</strong>
                        </th>
                        <th>
                            
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rates.map(rate => 
                            rate.customRates.map(customRate =>
                            <CustomRatesListItem 
                                rate={customRate} 
                                companyName={rate.companyName}
                                userId={rate.clientId}
                                apiKey={this.props.apiKey} 
                                refreshRates={this.props.refreshRates}
                            />
                            )
                        )
                    }
                </tbody>
            </Table>
        )
    }
}

export default CustomRatesList;