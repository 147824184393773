import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';

import createPackageType from '../../services/createPackageType.service';

function AddPackageType({apiKey, refreshPackageTypes}) {

  const [title, setTitle] = useState('')
  const [priceMultiplier, setPriceMultiplier] = useState("")

  const [maxLength, setMaxLength] = useState("")
  const [maxWidth, setMaxWidth] = useState("")
  const [maxHeight, setMaxHeight] = useState("")

  const [withWeight, setWithWeight] = useState(false)
  const [maxWeight, setMaxWeight] = useState("")


  const handleClear = () => {
    setTitle("")
    setPriceMultiplier("")
    
    setMaxLength("")
    setMaxWidth("")
    setMaxHeight("")

    setMaxWeight("")
    setWithWeight(false)
  }


  const handleSubmit = async (event) => {
    event.preventDefault()
    const createObject = {
      title,
      priceMultiplier,
      maxLengthM: parseFloat(maxLength),
      maxWidthM: parseFloat(maxWidth),
      maxHeightM: parseFloat(maxHeight),
      maxWeightKg: withWeight ? parseFloat(maxWeight) : undefined
    }

    console.log(createObject)

    const createdPackageType = await createPackageType(apiKey, createObject);
    if(createdPackageType) {
      handleClear();
      refreshPackageTypes();
    }
  }


  const formComplete = (
    title &&
    (priceMultiplier && priceMultiplier > 0) &&
    (maxLength && maxLength > 0) &&
    (maxWidth && maxWidth > 0) &&
    (maxHeight && maxHeight > 0) &&
    ((withWeight && maxWeight > 0) || !withWeight)
  )

  return (
    <Row xs={1} lg={2} className="g-4">
      <Col>
        <Card>
          <Card.Header>
            Add A Package Type
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Row>
              <Col>
                <Form.Control 
                  placeholder="Package Type Name"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                </Col>
                <Col>
                  <InputGroup>
                    <Form.Control 
                      placeholder="% of Standard Price"
                      type="number"
                      min="0"
                      step="5"
                      value={Math.round(priceMultiplier*100)}
                      onChange={(e) => setPriceMultiplier(e.target.value/100)}  
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  
                </Col>
              </Row>
              <hr/>
              <Row className="mb-3">
                <Col>
                  <Form.Control 
                    required
                    placeholder="Max. Length (Meters)"
                    type="number"
                    min="0"
                    step="0.05"
                    value={maxLength}
                    onChange={(e) => setMaxLength(e.target.value)} 
                  />
                </Col>
                <Col>
                  <Form.Control 
                    required
                    placeholder="Max. Width (Meters)"
                    type="number"
                    min="0"
                    step="0.05"
                    value={maxWidth}
                    onChange={(e) => setMaxWidth(e.target.value)} 
                  />
                </Col>
                <Col>
                  <Form.Control 
                    required
                    placeholder="Max. Height (Meters)"
                    type="number"
                    min="0"
                    step="0.05"
                    value={maxHeight}
                    onChange={(e) => setMaxHeight(e.target.value)} 
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Check
                    type="switch"
                    id="withWeight"
                    label="Set A Max Weight"
                    checked={withWeight}
                    onChange={() => setWithWeight(!withWeight)}
                  />
                </Col>
                <Col>
                  <Form.Control 
                    required={withWeight}
                    hidden={!withWeight} 
                    placeholder="Max Weight (Kilograms)" 
                    type="number"
                    min="0"
                    step="0.25"
                    value={maxWeight}
                    onChange={(e) => setMaxWeight(e.target.value)} 
                  />
                </Col>
                <Col>
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col align='right'>
                  <Button 
                    type="submit"
                    disabled={!formComplete}
                  >
                    Create
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    
  )
}

AddPackageType.propTypes = {
  refreshPackageTypes: PropTypes.func.isRequired,
  apiKey: PropTypes.string.isRequired
}

export default AddPackageType;