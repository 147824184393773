import React, { useEffect, useState } from 'react';
import {Row, Col, Button, Form, Alert} from 'react-bootstrap';

import resetUserPassword from '../../services/resetUserPassword.service';

function ResetPassword({user_id}) {
    const [nextPassword, setNextPassword] = useState('');
    const [nextPasswordConfirm, setNextPasswordConfirm] = useState('');
    const [passwordUpdate, setPasswordUpdate] = useState(false);

    useEffect(() => {
      setTimeout(() => setPasswordUpdate(false), 2500);          
    }, [passwordUpdate])

    const resetPassword = async (event) => {
        event.preventDefault();
        
        const result = await resetUserPassword(user_id, nextPassword);

        setNextPassword('');
        setNextPasswordConfirm('');
        
        if(result) {
            setPasswordUpdate(true);
        }
    }

    const passwordMinChars = 8;
    const passwordSecureChars = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/;

    const nextPasswordMinLength = (nextPassword.length >= passwordMinChars);
    const nextPasswordSecure = passwordSecureChars.test(nextPassword);
    const nextPasswordsMatch = (nextPassword === nextPasswordConfirm);

    const isValidPassword =(nextPasswordMinLength && nextPasswordSecure && nextPasswordsMatch);

    const NextPasswordLengthAlert = (
        <Alert variant='secondary'>
            Password cannot be less than eight characters in length.
        </Alert>
    )

    const NextPasswordInsecureAlert = (
        <Alert variant='secondary'>
            Password must contain an upper and lowercase alphabet character,
            a numeric character, and a special character (!@#$%^&*).
        </Alert>
    )

    const NextPasswordMismatchAlert = (
        <Alert variant='secondary'>
            Passwords do not match.
        </Alert>
    )

    const PasswordResetAlert = (
        <Alert variant='success'>
            Password Successfully Reset!
        </Alert>
    )

    return (
        <Form.Group>
            <Row>
                <Col>
                    <Form.Label>
                        <h6>Reset Password</h6>
                    </Form.Label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Text className="text-muted">
                        New Password
                    </Form.Text>
                    <Form.Control 
                        type='password' 
                        id='newPassword'
                        value={nextPassword}
                        onChange={event => setNextPassword(event.target.value)}
                    />

                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Text className="text-muted">
                        Confirm New Password
                    </Form.Text>
                    <Form.Control 
                        type='password'
                        id='confirmNewPassword'
                        value={nextPasswordConfirm}
                        onChange={event => setNextPasswordConfirm(event.target.value)}
                    />
                    <br/>
                    {
                        (nextPassword) 
                        ? (!nextPasswordMinLength)
                            ? NextPasswordLengthAlert
                            :(!nextPasswordSecure)
                                ? NextPasswordInsecureAlert
                                : null
                        : null

                    }
                    {
                        (nextPasswordConfirm && !nextPasswordsMatch)
                        ? NextPasswordMismatchAlert
                        : null
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button 
                        className='mt-2' 
                        variant='secondary' 
                        align='right' 
                        onClick={resetPassword}
                        disabled={!isValidPassword}
                    >
                        Reset
                    </Button>
                    <br/>
                    {
                        (passwordUpdate)
                        ? PasswordResetAlert
                        : null
                    }
                </Col>
            </Row>

        </Form.Group>
    )
}

export default ResetPassword;