import React, {useState, useEffect} from 'react'; 
import { Col, Row, Form, Button, ButtonGroup } from 'react-bootstrap';
import changeAdminInfo from '../../services/changeAdminInfo.service';
import fetchAdmin from '../../services/fetchAdmin.service';

function Profile () {

    const [editing, setEditing] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [savedName, setSavedName] = useState('');
    const [savedEmail, setSavedEmail] = useState('');
    const [onMount, setOnMount] = useState(true)


    useEffect( () => {
        //Updates name and email when page is loaded for the first time
        if(onMount) {
        refreshAdmin().then (() => {
        revertChanges()      
        setOnMount(false);      
        });
        }
    })

    const refreshAdmin = async () => {
        const admin = await fetchAdmin();
        setSavedName(admin.name);
        setSavedEmail(admin.email);
    }
    const toggleEditing = () => {
        if (editing) revertChanges();
            setEditing(!editing);
    }

    const updateValue = (event) => {
        const key = event.target.id;
        const value = event.target.value;
        switch(key) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
        }
    }

    const revertChanges = async () => {
        setName(savedName);
        setEmail(savedEmail);
    }

    const submitChanges = (event) => {
        event.preventDefault()
        changeAdminInfo({
            name: name, 
            email: email
        }).then(()=> {
            refreshAdmin();
            setEditing(false);
        })
    }


        return(
            <Form className='m-4'>
                <Row>
                    <h3>Admin Profile</h3>
                </Row>
            <Row>
                <Col>
                    <Form.Group controlId="name">
                        <Form.Label><em>User Name</em></Form.Label>
                        <Form.Control 
                            type="input" 
                            value={name} 
                            onChange={updateValue}
                            readOnly={!editing}
                            plaintext={!editing}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
            <Col>
                    <Form.Group controlId="email">
                    <Form.Label><em>Email</em></Form.Label>

                        <Form.Control 
                            type="email" 
                            value={email} 
                            onChange={updateValue}
                            readOnly={!editing}
                            plaintext={!editing}

                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonGroup>
                        <Button 
                            variant="primary" 
                            type="button" 
                            disabled={editing} 
                            onClick={toggleEditing}
                        >
                            Edit
                        </Button>
                        <Button 
                            variant="primary" 
                            type="button" 
                            disabled={!editing} 
                            onClick={toggleEditing}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant={(editing) ? "secondary" : "primary"} 
                            type="submit" 
                            disabled={!editing} 
                            onClick={submitChanges}
                        >
                            Update
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
        )
}

export default Profile;