import React, {useState} from 'react'; 
import { Button, ButtonGroup, Modal, Row, Col } from 'react-bootstrap';
import verifyUser from '../../services/verifyUser.service';
import suspendUser from '../../services/suspendUser.service';
import unsuspendUser from '../../services/unsuspendUser.service';
import loginAsUser from '../../services/loginAsUser.service';
import AssignTeam from './AssignTeam.component';
import ResetPassword from './ResetPassword.component';
import PropTypes from 'prop-types';

function UserListItem ({user, teams, refreshUsers}) {
    const [showModal, setShowModal] = useState(false);

    const verifyuser = () => {
        verifyUser(user.apiKey).then(()=> {
           refreshUsers()
        })
    }

    const suspenduser = () => {
        suspendUser(user._id).then(()=> {
            refreshUsers()
        })
    }

    const unsuspenduser = () => {
        unsuspendUser(user._id).then(()=> {
            refreshUsers()
        })
    }

    const loginAsuser = () => {
        loginAsUser(user._id)
    }

    const handleShow = () => {
        setShowModal(true);
    }

    const handleClose = () => {
        setShowModal(false);
    }

        const verified = user.verified;
        const suspended = user.suspended;

        const addresses = user.address;
        return (
            <tr>
                <td>
                    {user.companyName}
                </td>
                <td>
                    {user.email}
                </td>
                <td>
                    {user._id}
                </td>
                <td>
                    {user.signUpDate}
                </td>
                <td>
                    {
                        addresses.map(address => (
                            (
                                address.unit || 
                                address.number || 
                                address.street || 
                                address.city || 
                                address.province || 
                                address.country || 
                                address.postalCode
                            )
                            ? 
                            (
                                <Row noGutters>
                                    <Col noGutters xs='auto'>
                                        {(address.unit) ? address.unit + ' - ': null}
                                        {address.number} {address.street},&nbsp;
                                        {address.city} {address.province} {address.country},&nbsp;
                                        {address.postalCode}
                                        {(address.default) ? ' (Default) ': ''}
                                    </Col>
                                    <Col noGutters xs='auto'>

                                        <AssignTeam
                                            user={user}
                                            updatingAddress={address}
                                            teams={teams}
                                            refreshUsers={refreshUsers}
                                        >
                                        <div className="onfleetTeam">
                                            &nbsp; {'>>>'} &nbsp;
                                            {
                                                (address.onfleetTeam.name) 
                                                ? <>{address.onfleetTeam.name} &#x270E;</>
                                                : <i>assign a team</i>
                                            }
                                        </div>
                                        </AssignTeam>
                                    </Col>
                                </Row>
                            )
                            : 
                            (
                                <Row>
                                    <Col>
                                    <i>Empty Address</i>
                                    </Col>
                                </Row>
                            )
                        ))
                    }



                </td>

                <td>
                    

                </td>
                <td align='right'>
                    <ButtonGroup size='md'>
                        <Button 
                            variant="primary" 
                            disabled={(!verified || suspended)}
                            onClick={loginAsuser}
                        >
                            Login
                        </Button>

                        <div>
                            <Button
                                variant="secondary"
                                onClick={handleShow}
                            >
                                Reset&nbsp;Password
                            </Button>
                            <Modal
                                show={showModal} 
                                onHide={handleClose} 
                                size='md'
                            >
                                <Modal.Header closeButton>
                                    <Row>
                                        <Col xs="auto">
                                            <Modal.Title>Reset</Modal.Title>
                                        </Col>
                                    </Row>
                                </Modal.Header>
                                <Modal.Body>
                                        <ResetPassword
                                            user_id={user._id}
                                        />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button 
                                        variant="primary" 
                                        onClick={handleClose}
                                    >
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>

                        <Button 
                            variant="danger"
                            onClick={
                                (!verified) 
                                ? verifyuser 
                                :   (!suspended) 
                                    ? suspenduser 
                                    : unsuspenduser
                            }
                        >
                            {
                                (!verified) 
                                ? "Verify" 
                                :   (!suspended) 
                                    ? "Suspend" 
                                    : "Unsuspend"
                            }
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
        )
}

UserListItem.propTypes = {
    refreshUsers: PropTypes.func.isRequired,
     users: PropTypes.arrayOf(PropTypes.object).isRequired,
     teams: PropTypes.object.isRequired
}

export default UserListItem;