import React, {useState} from 'react'; 
import { Col, Form, Row, Button, Alert } from 'react-bootstrap';
import changePassword from '../../services/changePassword.service';
function Password () {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');


    const getOldPassword = (e) => {
        setOldPassword(e.target.value);
    }

    const getNewPassword = (e) => {
        setNewPassword(e.target.value);

    }

    const getConfirmNewPassword = (e) => {
        setConfirmNewPassword(e.target.value);
    }

    const compareNewPassword = () => {
        if (newPassword === confirmNewPassword) {
            return true;
        }
        return false;
    }

    const doChangePassword = async (e) => {
        e.preventDefault();

        if(!compareNewPassword()) {
            alert("passwords do not match")
        } else {
            let res=changePassword(oldPassword, newPassword);
        }
    }

        return(
            <Form className='m-4'>
                <Row>
                    <h3>Change Password</h3>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="oldPassword">
                            <Form.Control
                                autoComplete="off"
                                type="password"
                                placeholder="Old Password"
                                onChange={getOldPassword}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group controlId="newPassword">
                            <Form.Control
                                type="password"
                                placeholder="New Password"
                                onChange={getNewPassword}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group controlId="newPasswordConfirm">
                            <Form.Control
                                type="password"
                                placeholder="Confirm New Password"
                                onChange={getConfirmNewPassword}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button 
                            variant="primary" 
                            type="submit"
                            onClick={doChangePassword}
                        >
                            Change
                        </Button>
                    </Col>
                </Row>

            </Form>
        )
}

export default Password;