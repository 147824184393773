import axios from "axios"

const doLogin = async (email, password) => {
    const loginInfo = {
        admin: {
            email,
            password
        }
    }

    const loginEndpoint = process.env.REACT_APP_AUTH_ENDPOINT + '/admin/login'
    try {
        let response = await axios.post(
            loginEndpoint,
            loginInfo,
            {withCredentials:true}
        )

        return true;
    

    } catch(err) {
        alert(err);
        return false;
    }
}

export default doLogin;