const axios = require('axios').default;

const getAdmin = async () => {
  const adminEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/admin`

  try {
    const result = await axios.get(
      adminEndpoint,
      {withCredentials: true}
    );
      return result.data;
  }

  catch(err) {
    return false
  } 
}

export default getAdmin;