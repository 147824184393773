const axios = require('axios').default;

const fetchPackageTypes = async (apiKey) => {
  const packageTypesEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/package-types/`
  const result = await axios.get(packageTypesEndpoint,{
      headers: {'X-API-Key': apiKey}
    }
  );
  
  const packageTypes = result.data.data;
  return packageTypes;
}

export default fetchPackageTypes;