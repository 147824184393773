const axios = require('axios').default;

const fetchZones = async (apiKey) => {
  const zonesEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/zones/`
  const result = await axios.get(zonesEndpoint,{
      headers: {'X-API-Key': apiKey}
    }
  );
  
  const zones = result.data.data;
  return zones;
}

export default fetchZones;