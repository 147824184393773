import React, {useState, useEffect} from 'react'; 
import { Table } from 'react-bootstrap';
import UserListHeader from './UserListHeader.component';
import UserListItem from './UserListItem.component';
import PropTypes from 'prop-types';
import { propTypes } from 'react-bootstrap/esm/Image';

function UserList ({users, teams, refreshUsers}) {

    const [usersState, setUsersState] = useState([]);
    const [sortType, setSortType] = useState("NAME");

    useEffect(() => {
        let sortedUsers = []
            switch (sortType){
                case "NAME":
                    sortedUsers = sortUsersByName(users);
                    break;
                case "JOINDATE":
                    sortedUsers = sortUsersByJoinDate(users);
                    break;
                default:
                    sortedUsers = users;
            }
            setUsersState(sortedUsers);
    },[users])

   const sortUsersByName = (users) => {
        return users.sort((a, b) => a.companyName.localeCompare(b.companyName))
    }

    const sortUsersByJoinDate = (users) => {
        return users.sort((a, b) => a.signUpDate -  b.signUpDate)
    }

        const sortedUsers = usersState;
        let listItems = []

        sortedUsers.forEach(user => {
            let listItem = (
                <UserListItem 
                    user={user}
                    refreshUsers={refreshUsers}
                    teams={teams}
                />
            );
            
            listItems.push(listItem);
        });

        return (
            <Table striped>
                <UserListHeader></UserListHeader>
                <tbody>
                    {listItems} 
                </tbody>

            </Table>
        )
}

UserList.propTypes = {
    refreshUsers: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    teams: PropTypes.object.isRequired
}


export default UserList;