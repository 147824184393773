import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App';
import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Lato: 300,400,700', 'sans-serif']
  }
})
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
