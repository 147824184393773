const axios = require('axios').default;

const deleteRate = async (apiKey, userId, zoneId) => {
  const deleteCustomRateEndpoint = `${process.env.REACT_APP_DELIVERIES_ENDPOINT}/zones/removeCustomRate`
  const result = await axios.post(
      deleteCustomRateEndpoint, 
      {zoneId: zoneId, userId: userId},
      {headers: {'X-API-Key': apiKey}}
  );

  const rate = result.data.data;
  return rate;
}

export default deleteRate;