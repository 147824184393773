const axios = require('axios').default;

const createUser = async (email, companyName, password) => {
  const usersEndpoint = `${process.env.REACT_APP_AUTH_ENDPOINT}/users/`
  const result = await axios.post(
    usersEndpoint,
    {
        user: {
            email: email,
            companyName: companyName,
            password: password,
        }

    },
    {withCredentials:true}
  );
  
  const user = result.data;
    return user
}

export default createUser;