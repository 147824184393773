import React from 'react'; 
import Profile from './Profile.component'
import { Col, Row } from 'react-bootstrap';
import Password from './Password.component';

function Home () {
        return(
            <React.Fragment>
                <br/>
                <br/>
                <Row>
                    <Col >
                        <Profile/>
                    </Col>
                    <Col>
                        <Password/>
                    </Col>
                </Row>
            </React.Fragment>

        )
}

export default Home;