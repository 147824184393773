import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Card, Button} from 'react-bootstrap';

import deletePackageType from '../../services/deletePackageType.service';

function PackageTypeListItem({packageType, refreshPackageTypes, apiKey}) {

  const handleDelete = async () => {
    const deleted = await deletePackageType(apiKey, packageType._id)
    if(deleted) refreshPackageTypes()
  }



  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>{packageType.title}</Card.Title>
          <Card.Subtitle>{packageType.priceMultiplier * 100}% of client price</Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <ul>
            <li>Max Length: {packageType.maxLengthM} Meters</li>
            <li>Max Width: {packageType.maxWidthM} Meters</li>
            <li>Max Height: {packageType.maxHeightM} Meters</li>
            {
              packageType.maxWeightKg 
              ? <li>Max Weight: {packageType.maxWeightKg}</li>
              : <li><em>No maximum weight specified</em></li>
            }
          </ul>
        </Card.Body>
        <Card.Footer align='right'>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Card.Footer>
      </Card>
    </>
  )
}

PackageTypeListItem.propTypes = {
  packageType: PropTypes.object.isRequired,
  refreshPackageTypes: PropTypes.func.isRequired,
  apiKey: PropTypes.string.isRequired
}

export default PackageTypeListItem;