import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

const Navlink = ( props ) => {
    const location = useLocation()
    const isActive =  (location.pathname === props.href);
    const className = isActive ? 'active' : '';

    return(
        <Nav.Link className={className} {...props} >
            {props.children}
        </Nav.Link>
    );
}

export default Navlink;
