import React, {useState} from 'react';
import {Row, Col, Modal, Button, Form, ButtonGroup} from 'react-bootstrap';
import assignTeam from '../../services/assignTeam.service';
import PropTypes from 'prop-types';

function Profile ({teams, user, updatingAddress, refreshUsers, children}) {

    const [showModal, setShowModal] = useState(false);
    const [newTeam, setNewTeam] = useState(false);
    const [existingTeam, setExistingTeam] = useState(false);
    const [clearTeam, setClearTeam] = useState(true);
    const [teamObject, setTeamObject] = useState({id:'', name: ''});
    const [userId, setUserId] = useState(''); 

    const toggleAssignType = (event) => {

        let nextState = {
            newTeam: false,
            existingTeam:false,
            clearTeam:false,
            teamObject: {id:'', name: ''}
        }
        
        nextState[event.target.id] = true;
        setTeamObject(nextState.teamObject);
        setNewTeam(nextState.newTeam);
        setExistingTeam(nextState.existingTeam);
        setClearTeam(nextState.clearTeam);
        
    }

    const editTeamObject = event => {
        if(newTeam) {
            let teamName = event.target.value;
            setTeamObject({ id: '', name: teamName})
        }

        else if (existingTeam && event.target.value !== "select...") {
            let teamName = event.target.value;
            let teamId = teams.filter(team => team.name === teamName)[0].id;
            setTeamObject({ id: teamId, name: teamName});
        }

        else if (clearTeam) {
            setTeamObject({id: '', name: ''})
        }
    }

    const handleShow = () => {
        setShowModal(true);
    }

    const handleClose = () => {
        setShowModal(false);
    }

    const handleSubmit = async () => {
        try {
            await assignTeam(user, updatingAddress, teamObject)
        }
        catch(err) {
            alert(err)
        }
        refreshUsers();

        setShowModal(false);

    }

        return(
            <div>
                <div onClick={handleShow}>
                    {children}
                </div>
                <Modal
                    show={showModal} 
                    onHide={handleClose} 
                    size='md'
                >
                    <Modal.Header closeButton>
                        <Row>
                            <Col xs="auto">
                                <Modal.Title>Assign a Team</Modal.Title>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        <Row align='center'>
                            <Col>
                                <ButtonGroup toggle width='100%'>
                                    <Button id="newTeam" onClick={toggleAssignType}>New Team</Button>
                                    <Button id="existingTeam" onClick={toggleAssignType}>Existing Team</Button>
                                    <Button id="clearTeam" onClick={toggleAssignType}>Remove Team</Button>
                                </ButtonGroup>
                            </Col>

                        </Row>

                        <Row>
                        <Col>
                                <Form>
                                    <Form.Group controlId="newTeamInput" hidden={!newTeam}>
                                        <Form.Label>New Team</Form.Label>
                                        <Form.Control 
                                        type="input" 
                                        placeholder="Create a team"
                                        onChange={editTeamObject}
                                        value={teamObject.name}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="existingTeamInput" hidden={!existingTeam}>
                                        <Form.Label>Select Team</Form.Label>
                                        <Form.Control 
                                        as="select" 
                                        placeholder="select" 
                                        onChange={editTeamObject}
                                        value={teamObject.name}
                                        custom>
                                            <option>select...</option>
                                            { 
                                                (teams) 
                                                ? teams.map(
                                                        team => <option id={team.id}>{team.name}</option>
                                                    )
                                                : <option>loading teams...</option>
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="clearTeamInput" hidden={!clearTeam} align='center'>    
                                        <Form.Label >
                                            Click submit to clear team from user
                                        </Form.Label>
                                    </Form.Group>

                                </Form>    
                            </Col>
                        </Row>

                  
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                                variant="primary" 
                                onClick={handleClose}
                            >
                                Cancel
                        </Button>
                        <Button 
                                variant="secondary" 
                                onClick={handleSubmit}
                            >
                                Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        )
}

Profile.propTypes = {
    teams: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    updatingAddress: PropTypes.array.isRequired,
    refreshUsers: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired
}

export default Profile