import React from 'react';

import AppRouter from './components/_common/AppRouter.component'
import './styles/App.scss'


function App() {
  return <AppRouter/>
}

export default App;
